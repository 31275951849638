import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

const GlobalClickTracker = ({ userId }) => {
  useEffect(() => {
    const handleClick = (event) => {
      const target = event.target;

      if (target.tagName === 'BUTTON' || target.closest('button')) {
        const button = target.closest('button');
        const buttonText = button.innerText || button.getAttribute('data-tracking-id') || 'Unknown Button';

        ReactGA.event({
          category: 'User Interaction',
          action: 'Button Click',
          label: buttonText,
          value: userId,
        });

        console.log(`User ${userId} clicked: ${buttonText}`);
      }

      // Handle other clickable elements with React's onClick
      if (target.getAttribute('data-react-clicked')) {
        const elementText = target.getAttribute('data-tracking-id') || target.innerText || 'Unknown Element';

        ReactGA.event({
          category: 'User Interaction',
          action: 'Element Click',
          label: elementText,
          value: userId,
        });

        console.log(`User ${userId} clicked React element: ${elementText}`);
      }
    };

    const handleSubmit = (event) => {
      const form = event.target;
      const formName = form.getAttribute('name') || form.getAttribute('id') || 'Unknown Form';

      ReactGA.event({
        category: 'User Interaction',
        action: 'Form Submit',
        label: formName,
        value: userId,
      });

      console.log(`User ${userId} submitted form: ${formName}`);
    };

    // Attach the global listeners
    document.addEventListener('click', handleClick);
    document.addEventListener('submit', handleSubmit);

    return () => {
      // Cleanup listeners on component unmount
      document.removeEventListener('click', handleClick);
      document.removeEventListener('submit', handleSubmit);
    };
  }, [userId]);

  return null; // No UI needed
};

export default GlobalClickTracker;
