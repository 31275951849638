import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      userId: localStorage.getItem('userId') ? localStorage.getItem('userId') : 'non logged in user' 
    });
    console.log("Pageview sent for:", location.pathname + location.search);
  }, [location]);

  return null;
};

export default RouteChangeTracker;
